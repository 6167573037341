import React from 'react'
import img from './asset/logo.png'
function LodingWeb() {
  return (
    <div className="container loading_web">
        <img src={img} alt='logo'/>
        <h5>Loading....</h5>
    </div>
  )
}

export default LodingWeb