import { createContext, useContext, useEffect, useState } from "react";
import { messaging, getToken, onMessage } from '../Components/NavBarFooter/firebase'
import CryptoJS from "crypto-js";
export const Authdata = createContext();
export const Authprovider = ({ children }) => {
  // eslint-disable-next-line
  const [stateData, setstateData] = useState(null);
  const [cityData, setcityData] = useState(null);
  const [subCategory, setsubCategory] = useState([])
  const [category, setCategory] = useState([])
  const [registerData, setRegisterData] = useState()
  const [loading, setLoading] = useState(false);
  const [buttonLoding, setButtonLoding] = useState(false)
  const [loadingPage, setLodingPage] = useState(false)
  const [step, setStep] = useState(1);
  const [availbityLoad, setavailtyLoad] = useState(false)
  const [planLoad, setplanLoad] = useState(false)
  const [dashbord, setDashBord] = useState(true)
  const [responseStatus, setResponsestatus] = useState(false)
  const [error, setError] = useState({
    message: "",
    color: ""
  });
  const [loginData, setLogindata] = useState(null)
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const userData = loginData ? loginData : JSON.parse(localStorage.getItem("vendor_data"))

  const token = `Bearer ${userData?.data?.token}`
  const app_key = process.env.REACT_APP_APP_KEY;
  const img_url = process.env.REACT_APP_IMG_URL;
  const base_url = process.env.REACT_APP_BASE_URL;



  // let base_url = 'http://192.168.88.145:8000/api/'  //local
  let web_url = '/'


  const getState = async () => {
    try {
      const response = await fetch(`${base_url}states`, {
        headers: {
          "app-key": app_key
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();
      setstateData(jsonData?.data?.states);
    } catch (error) {

    } finally {
      // setLoading(false);
    }
  };
  const getCity = async (id) => {
    try {
      const response = await fetch(`${base_url}cities/${id}`, {
        headers: {
          "app-key": app_key
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();
      setcityData(jsonData?.data?.cities);
    } catch (error) {

    } finally {
      // setLoading(false);
    }
  };
  const [code, setCode] = useState()
  const sendCode = async (phone_number, email,is_verified) => {
    let obj={email:email,
      phone_number:phone_number,
      is_verified:is_verified
    }
    try {
      const response = await fetch(`${base_url}auth/send_code`, {
        method:"POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        setCode(jsonData);
      }
      else if (response?.status === 403) {
        setCode("success")
      }
      else {
        // handleClick()
        setCode(jsonData);
        // setError({message:jsonData?.data?.phone_number[0]?jsonData?.data?.phone_number[0]:jsonData?.data?.email[0],color:"error"})
      }

    } catch (error) {

    } finally {
      // setLoading(false); 
    }
  };
  const sendEmailVerification = async (token) => {
    try {
      const response = await fetch(`${base_url}auth/email_verification?data=${token}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (jsonData?.success) {

      }
      else {
        // handleClick()
        setCode(jsonData);
        // setError({message:jsonData?.data?.phone_number[0]?jsonData?.data?.phone_number[0]:jsonData?.data?.email[0],color:"error"})
      }

    } catch (error) {

    } finally {
      // setLoading(false); 
    }
  };
  const [profileCode, setProfileCode] = useState()
  const sendCodeProfile = async (phone) => {
    try {
      const response = await fetch(`${base_url}auth/resend_code?phone_number=${phone}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        setProfileCode(jsonData?.data);
        handleClick()
        setError({ message: "Please Check you phone or email ", color: "success" })
      }
      else if (response?.status === 403) {
        setProfileCode("success")
      }
      else {
        // handleClick()
        setProfileCode(jsonData);
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
        // setError({message:jsonData?.data?.phone_number[0]?jsonData?.data?.phone_number[0]:jsonData?.data?.email[0],color:"error"})
      }

    } catch (error) {
    } finally {
      // setLoading(false); 
    }
  };
  const [codeVerify, setVerify] = useState()
  const verifyCode = async (phone, code) => {
    try {
      const response = await fetch(`${base_url}auth/verify_code?phone_number=${phone}&code=${code}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      // setcityData(jsonData?.data?.cities);
      if (jsonData?.success) {
        setVerify(jsonData)
        handleClick()
        setError({ message: jsonData?.message, color: "success" })
      }
      else {
        handleClick()
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }

    } catch (error) {

    } finally {
      // setLoading(false);
    }
  };
  const register = async (obj) => {
    setLoading(true)
    try {
      const response = await fetch(`${base_url}auth/vendor/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setRegisterData(jsonData);
        setLoading(false)
        setStep(step + 1);
      }
      else {
        setLoading(false)
        handleClick()
        setError({ message: "Some thing went Wrong", color: "error" })
      }

    } catch (error) {

    }
  };
  const [clientRegisterData, setClientRegister] = useState()
  const clientRegister = async (obj) => {

    try {
      const response = await fetch(`${base_url}auth/client/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        handleClick()
        setError({ message: jsonData?.message, color: "success" })
        setClientRegister(jsonData)
      }
      if (jsonData?.errors) {
        setClientRegister(jsonData?.errors)
      }
    } catch (error) {

    }
  };
  const getCategry = async () => {
    setLodingPage(true)
    try {
      const response = await fetch(`${base_url}services`, {
        headers: {
          "app-key": app_key
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();
      setLodingPage(false)

      if (jsonData?.success) {
        setCategory(jsonData?.data);

      }
    } catch (error) {

      setLodingPage(false)

    } finally {
      // setLoading(false);
    }
  };
  const getSubCategry = async (id) => {
    try {
      const response = await fetch(`${base_url}services/${id}`, {
        headers: {
          "app-key": app_key
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();
      if (jsonData?.success) {

        setsubCategory(jsonData?.data?.sub_services);
      }
    } catch (error) {

    } finally {
      // setLoading(false);
    }
  };
  useEffect(() => {
    onMessage(messaging, (payload) => {
      // alert(payload.notification.body);
      handleClick()
      setError({message:payload.notification.body,color:"succes"})
      getNotifcation({ page: 1, per_page: "10" });
    });
  }, []);
  const socailLogin = async (obj) => {
    setButtonLoding(true);
    setDashBord(true);
    let tokenFireBase

    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
      tokenFireBase = await getToken(messaging, { vapidKey: "BKLGf5Y02ExVNpYQ104Fepho-HZP1jsjYHdJ4KoHVlM3k5xjJ1x96Yt0SvAdBteB2plrQwuKk9g173Hnd7KJzro" });
      } else {
        console.error('Permission denied');
      }
    } catch (error) {
      console.error('Error requesting permission or getting token:', error);
    }

    try {
      const response = await fetch(`${base_url}auth/social-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key
        },
        body: JSON.stringify({ ...obj, device_token: tokenFireBase })
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        setLogindata(jsonData);
        setButtonLoding(false);
        // handleClick();
        // setError({ message: jsonData?.message, color: "success" });
        // console.log('Login successful:', jsonData);
      } else {
        const newObj = {
          role: obj?.role,
          message: jsonData?.message,
          errors: jsonData?.errors,
          success: jsonData?.success
        };
        setLogindata(newObj);
        setButtonLoding(false);
        setError({ message: jsonData?.message, color: "error" });
      }
    } catch (error) {
      console.error('Error during login request:', error);
      setButtonLoding(false);
    }
  };
  const venderLogin = async (obj) => {
    setButtonLoding(true);
    setDashBord(true);
    let tokenFireBase

    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        tokenFireBase = await getToken(messaging, { vapidKey: "BKLGf5Y02ExVNpYQ104Fepho-HZP1jsjYHdJ4KoHVlM3k5xjJ1x96Yt0SvAdBteB2plrQwuKk9g173Hnd7KJzro" });
      } else {
        console.error('Permission denied');
      }
    } catch (error) {
      console.error('Error requesting permission or getting token:', error);
    }

    try {
      const response = await fetch(`${base_url}auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key
        },
        body: JSON.stringify({ ...obj, device_token: tokenFireBase })
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        setLogindata(jsonData);
        setButtonLoding(false);
        // handleClick();
        // setError({ message: jsonData?.message, color: "success" });
        // console.log('Login successful:', jsonData);
      } else {
        const newObj = {
          role: obj?.role,
          message: jsonData?.message,
          errors: jsonData?.errors,
          success: jsonData?.success
        };
        setLogindata(newObj);
        setButtonLoding(false);
        setError({ message: jsonData?.message, color: "error" });
      }
    } catch (error) {
      console.error('Error during login request:', error);
      setButtonLoding(false);
    }
  };

  const [forgetdata, setforgetdata] = useState()
  const passwordForget = async (obj) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}auth/forgot_password?email=${obj?.email}`, {
        headers: {
          "app-key": app_key
        }
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        setforgetdata(jsonData?.data?.link)
        handleClick()
        setError({ message: jsonData?.message, color: "success" })
        setButtonLoding(false)

      }
      else {
        handleClick()
        setError({ message: "User Not Found!", color: "error" })
        setButtonLoding(false)

      }
    } catch (error) {

    }
  }
  const changeProfile = async (obj) => {
    try {
      const response = await fetch(`${base_url}profile/update_profile_picture`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (jsonData?.success) {

        handleClick()
        setError({ message: jsonData?.message, color: "success" })
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const profileSetting = async (obj) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}profile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setButtonLoding(false)
        if (userData && userData.data && userData.data.user) {
          // Assuming jsonData is defined and contains the response data
          let newUserData = { ...jsonData.data };
          // Preserve the existing avatar field
          newUserData.avatar = userData.data.user.avatar;
          newUserData.service_id = userData.data.user.service_id;
          // Update userData.data.user with the newUserData
          userData.data.user = newUserData;
          // Store the updated userData in localStorage
          localStorage.setItem("vendor_data", JSON.stringify(userData));
        } else {
          console.error("Data retrieved from localStorage is invalid");
        }
        handleClick()
        setError({ message: "Profile Update Successfully", color: "success" })
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const changePassword = async (obj) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}auth/change_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        handleClick()
        setError({ message: "Password Change Successfully", color: "success" })
        setResponsestatus(true)
        setButtonLoding(false)
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
        setButtonLoding(false)
      }
    } catch (error) {

    }
  }
  const [resendEmailData, setResendEmail] = useState()
  const resendEmail = async (email) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}auth/send_verification_email?email=${email}`, {
        headers: {
          "app-key": app_key
        }
      });

      const jsonData = await response.json();
      if (jsonData?.success) {
        handleClick()
        setError({ message: jsonData?.message, color: "success" })
        setResendEmail(jsonData?.data?.link)
        setButtonLoding(false)
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
        setButtonLoding(false)
      }
    } catch (error) {

    } finally {
      // setLoading(false);
    }
  };
  const [vedorServiceData, setVendorService] = useState([])
  const vendorServices = async (type, value) => {
    try {
      const response = await fetch(`${base_url}vendor-services?type=${type}&all_services=${value}&debug=1`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        }
      });
      const jsonData = await response.json();
      if (jsonData?.data) {
        setVendorService(jsonData?.data)

      }

    } catch (error) {

    }
  }
  const [planData, setPlanData] = useState([])
  const getPlanByService = async (data) => {
    setLodingPage(true)
    try {
      const response = await fetch(`${base_url}plans/get-plans-by-service?debug=1&${data?.type === "service" ? "vendor_sub_service_id" : "studio_id"}=${data?.studio_id ? data?.studio_id : data?.service_id}&type=${data?.type}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        if (jsonData?.data) {
          setPlanData(jsonData?.data)
        }
        setplanLoad(false)

      }


    } catch (error) {

      setplanLoad(false)
    }
  }

  const [planNameData, setPlanName] = useState()
  const plansName = async (type) => {
    try {
      const response = await fetch(`${base_url}plans/get-plan-templates?debug=1&type=${type}`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        }
      });
      const jsonData = await response.json();
      if (jsonData?.data) {
        setPlanName(jsonData)

      }
      else {
        handleClick()
        setError({ message: "User Not Found!", color: "error" })
      }
    } catch (error) {

    }
  }
  const createPlans = async (obj, type) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}plans/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify({ plans: obj, booking_service_type: type })
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        handleClick()
        setError({ message: "Plans Create Successfully", color: "success" })
        setButtonLoding(false)

      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
        setButtonLoding(false)
      }
    } catch (error) {

    }
  }
  const editPlans = async (obj, type) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}plans/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify({ plans: obj, booking_service_type: type })
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        handleClick()
        setError({ message: "Plan Update Successfully", color: "success" })
        setButtonLoding(false)
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
        setButtonLoding(false)
      }
    } catch (error) {

    }
  }
  const [respForPass, setForPassResp] = useState()
  const forgetPasswordNew = async (obj) => {
    try {
      const response = await fetch(`${base_url}auth/reset_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (jsonData?.success) {
        handleClick()
        setForPassResp(jsonData)
        setError({ message: jsonData?.message, color: "success" })
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const serviceSetting = async (obj) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}vendor-services/update?debug=1`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify({ vendor_services: obj })
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        handleClick()
        setError({ message: "Change Skills Successfully", color: "success" })
        vendorServices("photography", 1);
        setButtonLoding(false)

      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
        setButtonLoding(false)

      }
    } catch (error) {

    }
  }
  const [serviceSetingData, setAdminServiceData] = useState()
  const [serviceLoding, setSeriveLoding] = useState(false)
  const adminserviceSetting = async (id, ids) => {
    try {
      const response = await fetch(`${base_url}service-setting?vendor_sub_service_id=${id}&vendor_service_id=${ids}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setAdminServiceData(jsonData?.data)

      }
    } catch (error) {

    }
  }
  const [adminGellery, setGelleryData] = useState([])
  const adminserviceGellery = async (id, ids) => {
    try {
      const response = await fetch(`${base_url}service-gallery?vendor_sub_service_id=${id}&vendor_service_id=${ids}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setGelleryData(jsonData?.data)
        setSeriveLoding(false)
      }

    } catch (error) {

    }
  }
  const adminserviceGelleryDelete = async (id, service_id, sub_service_id) => {
    try {
      const response = await fetch(`${base_url}service-gallery/delete-image/${id}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        // setGelleryData(jsonData?.data)
        adminserviceGellery(service_id, sub_service_id)
        handleClick()
        setError({ message: jsonData?.message, color: "success" });
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const serviceSettingAdmin = async (obj) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}service-setting/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        handleClick()
        setError({ message: "Change Skills Successfully", color: "success" })
        setButtonLoding(false)
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
        setButtonLoding(false)
      }
    } catch (error) {

    }
  }
  const [availDaysData, setAvailDays] = useState()
  const availDays = async () => {
    try {
      const response = await fetch(`${base_url}get-days`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        // setGelleryData(jsonData?.data)
        setAvailDays(jsonData?.data)
      }

    } catch (error) {

    }
  }
  const [slotData, setSlotData] = useState([])
  const getSlot = async (data) => {
    setLodingPage(true)
    try {
      const response = await fetch(`${base_url}availability?type=${data.type}&plan_id=${data?.plan_id}&debug=1
      `, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      setLodingPage(false)
      if (response.status === 200 || response.status === 201) {
        // setGelleryData(jsonData?.data)
        setSlotData(jsonData?.data)
        setavailtyLoad(false)
      }

    } catch (error) {

    }
  }

  const createSlot = async (obj) => {
    try {
      const response = await fetch(`${base_url}availability/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        let sentData = {
          type: obj?.availability?.type,
          plan_id: obj?.availability?.plan_id
        }
        if (obj?.availability?.type === "service") {
          vendorServices("photography", 0)
        }
        else {
          getStudio()
        }
        getSlot(sentData)
        handleClick()
        setError({ message: "Slot Create Successfully", color: "success" })
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const updateCart = async (obj) => {
    let array = []
    obj?.map((item) => {
      let data = {
        id:item?.cart_id,
        quantity: item?.quantity,
      
      }
      array.push(data)
    })
    try {
      const response = await fetch(`${base_url}client/cart/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify({ carts: array })
      });
      // const jsonData = await response.json();
      // if (response.status === 200 || response.status === 201) {
      //   handleClick()
      //   setError({ message: "Slot Create Successfully", color: "success" })
      // }
      // else {
      //   handleClick()
      //   setError({ message: jsonData?.message, color: "error" })
      // }
    } catch (error) {

    }
  }
  const updateslot = async (obj) => {
    try {
      const response = await fetch(`${base_url}availability/update/${obj?.availability?.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        if (obj?.availability?.type === "service") {
          vendorServices("photography", 0)
        }
        else {
          getStudio()
        }
        handleClick()
        setError({ message: "Slot Update Successfully", color: "success" })
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const addStudio = async (obj) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}studios/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        getStudio()
        handleClick()
        setError({ message: "Studio Added Successfully", color: "success" })
        setButtonLoding(false)

        return { success: true }
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const updateStudio = async (obj) => {
    setButtonLoding(true)

    try {
      const response = await fetch(`${base_url}studios/update/${obj?.id}?debug=1`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        getStudio()
        handleClick()
        setError({ message: "Studio Update Successfully", color: "success" })
        setButtonLoding(false)
        return { success: true }
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const [studioData, setStudio] = useState([])
  const getStudio = async () => {
    try {
      const response = await fetch(`${base_url}studios`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        // setGelleryData(jsonData?.data)
        setStudio(jsonData?.data)
      }

    } catch (error) {

    }
  }
  const [duration, setduration] = useState()
  const getDuration = async (id) => {
    try {
      const response = await fetch(`${base_url}plans/get-single-plan/${id}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setduration(jsonData?.data?.plan_duration)
      }

    } catch (error) {

    }
  }
  const delStudio = async (id) => {
    try {
      const response = await fetch(`${base_url}studios/delete-studio/${id}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      if (response.status === 200 || response.status === 201) {
        getStudio()
        handleClick()
        setError({ message: "Studio Delete Successfully", color: "success" })
      }

    } catch (error) {

    }
  }
  const [studioGalleryData, setStudioGelleryData] = useState([])
  const getStudioGellery = async (id) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}studios/get-settings/${id}?debug=1`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        // setGelleryData(jsonData?.data)
        setStudioGelleryData(jsonData?.data)
        setTimeout(() => {
          setButtonLoding(false)
        }, 1000);
      }

    } catch (error) {

    }
  }
  const deleteStudioGellery = async (id, studio_id) => {
    try {
      const response = await fetch(`${base_url}studios/delete-image/${id}?debug=1`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      if (response.status === 200 || response.status === 201) {
        // setGelleryData(jsonData?.data)
        setError({ message: "Studio image Delete Successfully", color: "success" })
        getStudioGellery(studio_id)
      }

    } catch (error) {

    }
  }
  const [vendorList, setVemdorList] = useState()
  const getvendorList = async (id, filter) => {
    setLodingPage(true)
    try {
      const response = await fetch(`${base_url}services/vendors/${id}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(filter)
      });
      const jsonData = await response.json();
      setLodingPage(false)

      if (response.status === 200 || response.status === 201) {
        setVemdorList(jsonData?.data)
        setLodingPage(false)

        // getStudioGellery(studio_id)
      }

    } catch (error) {

      setLodingPage(false)

    }
  }
  const [studioList, setStudioList] = useState()
  const getstudioList = async (id, filter) => {
    setLodingPage(true)
    try {
      const response = await fetch(`${base_url}studios/vendors`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(filter)
      });
      const jsonData = await response.json();
      setLodingPage(false)
      if (response.status === 200 || response.status === 201) {
        setStudioList(jsonData?.data)
        setLodingPage(false)
        // getStudioGellery(studio_id)
      }

    } catch (error) {
      setLodingPage(false)

    }
  }
  const [profileData, setProfileData] = useState()
  const getVendorProfile = async (username, slug) => {
    setLodingPage(true)
    try {
      const response = await fetch(`${base_url}profile/${username}/${slug}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setProfileData(jsonData?.data)
        // getStudioGellery(studio_id)
        setLodingPage(false)
      }
      else {
        setProfileData(jsonData)
        setLodingPage(false)
        setError({ message: jsonData?.message, color: "error" })
      }

    } catch (error) {

    }
  }
  const [studioProfileData, setstudioProfileData] = useState()
  const getStudioProfile = async (username) => {
    try {
      const response = await fetch(`${base_url}studios/get-studio-by-slug?slug=${username}`, {
        headers: {
          "app-key": app_key
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setstudioProfileData(jsonData?.data)
        // getStudioGellery(studio_id)
      }

    } catch (error) {

    }
  }
  const [slotDataVendor, setslodeData] = useState()
  const venderAvailiblty = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(`${base_url}slots?vendor_id=${data?.vendor_id}&plan_availability_id=${data?.plan_availability_id}&day_name=${data?.day_id}&date=${data?.date}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      setButtonLoding(false)
      if (response.status === 200 || response.status === 201) {
        setslodeData(jsonData?.data)
        setLoading(false);
      }

    } catch (error) {

      setLoading(false);
    }
  }
  const profilePlan = async (id, service_id) => {
    try {
      const response = await fetch(`${base_url}plans/get-plans-by-vendor?vendor_id=${id}&vendor_service_id=${service_id}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      if (response.status === 200 || response.status === 201) {
        // setGelleryData(jsonData?.data)
        // getStudioGellery(studio_id)
      }
    } catch (error) {

    }
  }
  const [addCartResp, setCartResp] = useState()

  const addToCart = async (obj) => {
    setDashBord(false)
    try {
      const response = await fetch(`${base_url}client/cart/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify({ plans: [obj] })
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        // setCartResp(jsonData)
        setCartResp("ok")
        handleClick()
        setError({ message: "Service Added in cart Successfully", color: "success" })
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const emailCheck= async (obj) => {
    setDashBord(false)
    try {
      const response = await fetch(`${base_url}client/cart/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify({ plans: [obj] })
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        // setCartResp(jsonData)
        setCartResp("ok")
        handleClick()
        setError({ message: "Service Added in cart Successfully", color: "success" })
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const [cartData, setCartData] = useState()
  const getCart = async () => {
    try {
      const response = await fetch(`${base_url}client/cart`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setCartData(jsonData)
        // getStudioGellery(studio_id)
      }

    } catch (error) {

    }
  }
  const removeCart = async (id) => {
    try {
      const response = await fetch(`${base_url}client/cart/remove-service?cart_id=${id}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      if (response.status === 200 || response.status === 201) {
        getCart()
      }

    } catch (error) {

    }
  }
  const [orderData, setOrderData] = useState([])
  const getOrder = async (obj) => {
    setButtonLoding(true)
    const data = {
      per_page: obj?.entry,
      page: obj?.page,
      order_number: obj?.order_number,
      created_at: obj?.added_date,
      updated_at: obj?.update_date,
      order_status: obj?.order_status,
      name: obj?.first_name,
      amount: obj?.total,


    }
    try {
      const response = await fetch(`${base_url}${userData?.data?.role === "client" ? "client" : "vendor"}/orders/get-orders?order_status_code=${obj?.id}`, {
        method: "POST",

        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          "Authorization": token,
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setOrderData(jsonData)
        setTimeout(() => {

          setButtonLoding(false)
        }, 2000)
      }

    } catch (error) {
      // console.log(error?.message)
    }
  }
  const [orderStatusData, setOrderStatus] = useState([])
  const orderStatus = async () => {
    try {
      const response = await fetch(`${base_url}get/order-statuses`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        // setGelleryData(jsonData?.data)
        setOrderStatus(jsonData?.order_statuses)
      }

    } catch (error) {

    }
  }


  const [orderDone, setOrderDone] = useState()
  const createOrder = async (obj, status) => {
    try {
      const response = await fetch(`${base_url}client/orders/store`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setOrderDone(jsonData?.data)
        userData.data.address = obj?.location
        userData.data.city_id = obj?.city_id
        userData.data.state_id = obj?.state_id
        localStorage.setItem("vendor_data", JSON.stringify(userData));
        handleClick()
        setError({ message: "Service Added in cart Successfully", color: "success" })
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const changeOrderStatus = async (obj, pagination) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}${userData?.data?.role === "client" ? "client" : "vendor"}/orders/update-status/${obj?.id}/${obj?.id_service ? obj?.id_service : ""}
`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        getOrder(pagination)
        setOrderDone(jsonData?.data)
        handleClick()
        setError({ message: "Service Added in cart Successfully", color: "success" })
        setButtonLoding(false)
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
        setButtonLoding(false)
      }
    } catch (error) {

    }
  }
  const checkSlot = async (obj) => {
    try {
      const response = await fetch(`${base_url}client/cart/check-slots`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify({ slots: obj })
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        const isAnyReserved = jsonData?.data?.cart?.some(cart => cart.slot.is_reserved === 1);
        return isAnyReserved;
      } else {
        handleClick();
        setError({ message: jsonData?.message, color: "error" });
      }
    } catch (error) {
      ;
    }
    return false;
  };

  const serviceTogaleStatus = async (obj, data) => {
    try {
      const response = await fetch(`${base_url}service-gallery/update?media=${obj?.media}&type=${obj?.type}&status=${obj?.status}`, {

        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },

      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        adminserviceGellery(data?.vendor_sub_service_id, data?.vendor_service_id);
      }
      else {
        handleClick()
        setError({ message: jsonData?.message, color: "error" })
      }
    } catch (error) {

    }
  }
  const [slot, setSlot] = useState([])
  const filter = async (obj) => {
    try {
      const response = await fetch(`${base_url}availability/get-users`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setSlot(jsonData?.data)
      }
    } catch (error) {

    }
  }
  const [amintyData, setAmintyData] = useState([])
  const getAminty = async () => {
    try {
      const response = await fetch(`${base_url}studios/amenities`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setAmintyData(jsonData?.data)
      }
    } catch (error) {

    }
  }
  const [portfolioData, setPortfolioData] = useState({})
  const getPortFolioImage = async (sub_service_id, service_id) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}vendor/services/portfolios?vendor_sub_service_id=${sub_service_id}&vendor_service_id=${service_id}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        // setGelleryData(jsonData?.data)
        setPortfolioData(jsonData?.data)
        setTimeout(() => {

          setButtonLoding(false)
        }, 1000)
      }

    } catch (error) {

    }
  }
  const getStudioPortFolioImage = async (sub_service_id, service_id) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}vendor/studios/portfolios?studio_id=${sub_service_id}&vendor_service_id=${service_id}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        // setGelleryData(jsonData?.data)
        setPortfolioData(jsonData?.data)
        setTimeout(() => {

          setButtonLoding(false)
        }, 2000);
      }

    } catch (error) {

    }
  }
  const delPortFolioImage = async (id, service_id, sub_service_id, type) => {
    // let obj={
    //   service_id:data?.service_id,
    //   sub_service_id:data?.id,
    //   media_id:id,
    //   type:type
    // }
    // console.log(type);
    try {
      const response = await fetch(`${base_url}vendor/${type === "service" ? "services" : "studios"}/portfolios/delete/${id}`, {
        // method:"POST",
        headers: {
          'Authorization': token,
          "app-key": app_key
          // 'Content-Type': 'application/json',
          // 'Accept': 'application/json',
        },
        // body:JSON.stringify(obj)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        handleClick()
        setError({ message: jsonData?.message, color: "success" });
        if (type === "service") {

          getPortFolioImage(sub_service_id, service_id)
        }
        else {
          getStudioPortFolioImage(sub_service_id, service_id)
        }

      }

    } catch (error) {

    }
  }
  const togalPortFolioImage = async (id, service_id, sub_service_id, type) => {
    try {
      const response = await fetch(`${base_url}vendor/${type === "service" ? "services" : "studios"}/portfolios/toggle-status/${id}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        handleClick()
        setError({ message: jsonData?.message, color: "success" });
        if (type === "service") {
          getPortFolioImage(sub_service_id, service_id);

        }
        else {
          getStudioPortFolioImage(sub_service_id, service_id);

        }
      }

    } catch (error) {

    }
  }
  const togalStudioImage = async (obj) => {
    try {
      const response = await fetch(`${base_url}studios/toggle-status/${obj?.id}?type=${obj?.type}&status=${obj?.status}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      if (response.status === 200 || response.status === 201) {
        handleClick()
        setError({ message: "Status Update Successfully", color: "success" });
        getStudioGellery(obj?.studio_id)
        // if(type==="service"){
        //   getPortFolioImage(sub_service_id, service_id);

        //   }
        //   else{
        //   getStudioPortFolioImage(sub_service_id, service_id);

        // }
      }

    } catch (error) {

    }
  }
  const setPortFolioLink = async (data, type) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}vendor/${type === "service" ? "services" : "studios"}/portfolios/upload-link`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        handleClick()
        setError({ message: jsonData?.message, color: "success" });
        // if (type === "service") {
        //   getPortFolioImage(data?.studio_id, data?.vendor_service_id)

        // } else {
        //   getStudioPortFolioImage(data?.vendor_sub_service_id, data?.vendor_service_id)

        // }
        setButtonLoding(false)
      }

    } catch (error) {

    }
  }
  const [adminservice, setAdminService] = useState()
  const getAdminService = async () => {
    try {
      const response = await fetch(`${base_url}our-services`, {
        headers: {
          "app-key": app_key
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setAdminService(jsonData?.data)
      }

    } catch (error) {

    }
  }
  const [adminserviceDetail, setAdminServiceDetail] = useState()
  const getAdminServiceDetail = async (slug) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}service-detail/${slug}`, {
        headers: {
          "app-key": app_key
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setButtonLoding(false)
        setAdminServiceDetail(jsonData?.data)
      }

    } catch (error) {


    }
  }
  const [adminStudio, setAdminStudio] = useState()
  const getAdminStudio = async () => {
    try {
      const response = await fetch(`${base_url}our-studios`, {
        headers: {
          "app-key": app_key
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setAdminStudio(jsonData?.data)
      }

    } catch (error) {

    }
  }
  const [adminStudioDetail, setAdminStudioDetail] = useState()
  const getAdminStudioDetail = async (id) => {
    setButtonLoding(true)
    try {

      const response = await fetch(`${base_url}studio-detail/${id}`, {
        headers: {
          "app-key": app_key
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setButtonLoding(false)
        setAdminStudioDetail(jsonData?.data)
      }

    } catch (error) {

    }
  }
  const setOrderLinkData = async (data, pagination) => {
    try {
      const response = await fetch(`${base_url}order-links/store`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(data)
      });
      if (response.status === 200 || response.status === 201) {
        getOrder(pagination)
        handleClick()
        setError({
          message: "Data Sent Successfully", color: "success"
        })
      }

    } catch (error) {

    }
  }
  const [orderLinkData, setOrderLinkDatas] = useState([])
  const getOrderLinkData = async (orderServiceId) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}order-links/get-order-link-by-order-service?order_service_id=${orderServiceId}`, {

        headers: {

          'Authorization': token,
          "app-key": app_key
        },

      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setOrderLinkDatas(jsonData)
        setTimeout(() => {

          setButtonLoding(false)
        }, 1000)

      }

    } catch (error) {

    }
  }
  const sendRevision = async (orderLinkId, data, pagination) => {
    const formData = new FormData();
    formData.append('order_status_code', data?.order_status_code)
    formData.append('client_comment', data?.client_comment)
    formData.append('status', data?.status)
    if(data?.media){
      formData.append('media[]', data?.media);
    }
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}order-links/update-order-link/${orderLinkId}`, {
        method: "POST",
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
        body: formData
      });
      if (response.status === 200 || response.status === 201) {
        getOrder(pagination)
        setButtonLoding(false)
        handleClick()
        setError({
          message: "Revesion Sent Successfully", color: "success"
        })
      }

    } catch (error) {

    }
  }
  const markOrderComplete = async (data) => {
    try {
      const response = await fetch(`${base_url}client/order_reviews/update/${data?.id}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setOrderData(jsonData?.data)
      }

    } catch (error) {

    }
  }
  const contactUS = async (data) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}contact-us`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setButtonLoding(false)
        handleClick()
        setError({ message: jsonData?.message, color: "success" });
      }

    } catch (error) {

      setButtonLoding(false)
    }

  }


  const socialLinkStore = async (data) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}social-media-links/store`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token
        },
        body: JSON.stringify({ social_media: data })
      });
      // const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setButtonLoding(false)
        handleClick()
        setError({ message: "Social Links Update", color: "success" });
      }

    } catch (error) {

      setButtonLoding(false)
    }

  }

  const Enquiry = async (data) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}service-requests/store`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': token,
          "app-key": app_key
        },
        body: JSON.stringify(data)
      });
      if (response.status === 200 || response.status === 201) {

        setButtonLoding(false)
        handleClick()
        setError({
          message: "Enquiry Form Submit Successfully",
          color: "success"
        })
        return { succes: true }

      }

    } catch (error) {
      setButtonLoding(false)
      return { succes: false }
    }
  }
  const [notificationData, setNotFicationData] = useState({ notifications: [] })
  const getNotifcation = async (page) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}notifications?page=${page?.page}&per_page=${page?.per_page}`, {
        headers: {
          'Authorization': token,
          "app-key": app_key
        },
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        let array = [...jsonData?.notifications]
        setNotFicationData({ ...jsonData, notifications: array });
        setButtonLoding(false)
      }
    } catch (error) {

    }
  }
  const updateNotification = async (id) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}notifications/update-notification/${id}`, {

        headers: {

          'Authorization': token,
          "app-key": app_key
        },

      });
      if (response.status === 200 || response.status === 201) {
        let array = [...notificationData?.notifications]
        // eslint-disable-next-line
        array?.map((item) => {
          if (item?.notification_id === id) {
            item.is_read = 1
          }
        })
        let un_read_notifications_count = notificationData?.un_read_notifications_count - 1
        setNotFicationData({ ...notificationData, notifications: array, un_read_notifications_count: un_read_notifications_count })
        setButtonLoding(false)

      }

    } catch (error) {

    }
  }


  const [enquiryVendor, setEnquiryVendor] = useState()
  const VendorEnquiry = async (page) => {
    const data = {
      per_page: page?.per_page,
      page: page?.page,
      name: page?.first_name,

      email: page?.email,
      phone_number: page?.contact,
      location: page?.address,
      photoshoot_start_date: page?.added_date,
      service_timeline: page?.session_type
    }
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}service-requests`, {
        method: "POST",

        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          "Authorization": token,
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setEnquiryVendor(jsonData)
        setButtonLoding(false)
      }

    } catch (error) {

    }
  }


  const [globalFAQs, setGlobalFAQs] = useState()
  const FAQs = async (type) => {
    try {
      const response = await fetch(`${base_url}vendor/faqs/all?type=${type}`, {
        headers: {
          "app-key": app_key,
          'Authorization': token
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setGlobalFAQs(jsonData?.data)
      }

    } catch (error) {

    }
  }


  const [terms, setTerms] = useState()
  const TermsandPrivacy = async (type) => {
    try {
      const response = await fetch(`${base_url}site_pages/${type}`, {
        headers: {
          "app-key": app_key,
          'Authorization': token
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setTerms(jsonData?.data)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }

  const [number, setNumber] = useState()
  const ContactNumber = async (type) => {
    try {
      const response = await fetch(`${base_url}settings`, {
        headers: {
          "app-key": app_key,
          'Authorization': token,
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setNumber(jsonData?.data)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }



  const [cities, setCities] = useState([])
  const getCities = async (stateIds) => {
    setButtonLoding(true);

    const data = {
      states: stateIds
    };

    try {
      const response = await fetch(`${base_url}get-cities`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
        },
        body: JSON.stringify(data)

      });

      const jsonData = await response.json();

      if (response.status === 200 || response.status === 201) {
        setCities(jsonData?.data)
        setButtonLoding(false);

      }
    } catch (error) {
      ;
      setButtonLoding(false);
    }
  };
  const rescheduleOrder = async (orderStatus,serviceId, data) => {
    setButtonLoding(true);



    try {
      const response = await fetch(`${base_url}client/orders/reschedule/${serviceId}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          'Authorization': token

        },
        body: JSON.stringify(data)

      });

      // const jsonData = await response.json();

      if (response.status === 200 || response.status === 201) {
        getOrder({id:orderStatus})
        handleClick()
        setError({
          message: "Your Service Reschedule Successfully!"
        })

      }
    } catch (error) {
      ;
      setButtonLoding(false);
    }
  };

  const [home, setHome] = useState([])
  const [homePgeLoading, setHomePageLoding] = useState(false)
  const homePage = async () => {
    setHomePageLoding(true)
    try {
      const response = await fetch(`${base_url}site_pages/home-page`, {
        headers: {
          "app-key": app_key,
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setHome(jsonData?.data)
        setHomePageLoding(false)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const [webSettingData, setWebSetting] = useState()
  const webSetting = async () => {
    try {
      const response = await fetch(`${base_url}settings`, {
        headers: {
          "app-key": app_key,
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setWebSetting(jsonData)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const [clientDash, setClientDash] = useState([])
  const clientDashboard = async (obj) => {
    setButtonLoding(true)
    const data = {

      created_at: obj?.added_date,


      wildcard: obj?.first_name,

      status_code: obj?.status_code,
    }
    try {
      const response = await fetch(`${base_url}client/dashboard`, {

        method: "POST",

        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          "Authorization": token,
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setClientDash(jsonData)
        setTimeout(() => {

          setButtonLoding(false)
        }, 2000)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const [vendorDash, setVendorDash] = useState([])
  const vendorDashboard = async (obj) => {
    setButtonLoding(true)
    const data = {
      created_at: obj?.added_date,
      wildcard: obj?.first_name,
      status_code: obj?.order_status_code,
    }
    try {
      const response = await fetch(`${base_url}vendor/dashboard/orders`, {
        method: "POST",

        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          "Authorization": token,
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setVendorDash(jsonData?.data)
        setTimeout(() => {

          setButtonLoding(false)
        }, 2000)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const [vendorDashOrder, setVendorDashOrder] = useState([])
  const vendorDashboardOrder = async (obj) => {
    setButtonLoding(true)
    const data = {
      created_at: obj?.added_date,
      wildcard: obj?.first_name,
      status_code: obj?.order_status_code,
    }
    try {
      const response = await fetch(`${base_url}vendor/dashboard/recent-orders`, {
        method: "POST",

        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          "Authorization": token,
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setVendorDashOrder(jsonData?.data)
        setTimeout(() => {

          setButtonLoding(false)
        }, 2000)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const [paymentData, setPaymentData] = useState([])
  const getVendorPayment = async (data) => {
    setButtonLoding(true)
    try {
      const response = await fetch(`${base_url}vendor-payments`, {
        method: "POST",

        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          "Authorization": token,
        },
        body: JSON.stringify(data)
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setPaymentData(jsonData)
        setButtonLoding(false)

      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const requestPayment = async (data) => {
    setButtonLoding(true);
    try {
      const response = await fetch(`${base_url}withdraw-request/store`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          "Authorization": token,
        },
        body: JSON.stringify(data)

      });


      if (response.status === 200 || response.status === 201) {
        getVendorPayment()
        setButtonLoding(false);
        return { success: true }

      }
    } catch (error) {
      ;
      setButtonLoding(false);
    }
  };
  const [clientTex, setClientTex] = useState()
  const getClientTex = async (id) => {
    try {
      const response = await fetch(`${base_url}client/orders/order-taxes/${id}`, {
        headers: {
          "app-key": app_key,
          "Authorization": token,
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setClientTex(jsonData?.data)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const requestRefund = async (data, pagination) => {
    setButtonLoding(true);
    try {
      const response = await fetch(`${base_url}refund/store`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "app-key": app_key,
          "Authorization": token,
        },
        body: JSON.stringify(data)

      });


      if (response.status === 200 || response.status === 201) {
        getVendorPayment()
        setButtonLoding(false);
        handleClick()
        getOrder(pagination)
        setError({
          message: "Refund request Sent Successfully", color: "success"
        })
        return { success: true }

      }
    } catch (error) {
      ;
      setButtonLoding(false);
    }
  };
  const [availableDate, setavailableDate] = useState()
  
  const getavailableDate = async (id) => {
    // setLodingPage(true)
    try {
      const response = await fetch(`${base_url}slots-availability?plan_availability_id=${id?.plan_availability_id}&date=${id?.date}`, {
        headers: {
          "app-key": app_key,
          "Authorization": token,
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setavailableDate(jsonData?.data?.available_dates)
        setLodingPage(false)

      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const [social_linkData, setSocial_link] = useState([])
  const getSocialLInks = async () => {

    try {
      const response = await fetch(`${base_url}social-media-links/vendor-admin-links`, {
        headers: {
          "app-key": app_key,
          "Authorization": token,
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setSocial_link(jsonData?.data)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const [fbAppId, setFBAppID] = useState("")
  const facebookCrdentioal = async () => {
    try {
      const response = await fetch(`${base_url}social-auths?platform=facebook`, {
        headers: {
          "app-key": app_key,
          "Authorization": token,
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setFBAppID(jsonData?.data)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const [googleClientId, setgoogleClientId] = useState("")
  const getGoogleClientId = async () => {
    try {
      const response = await fetch(`${base_url}social-auths?platform=google`, {
        headers: {
          "app-key": app_key,
          "Authorization": token,
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setgoogleClientId(jsonData?.data)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }
  const [stripIdId, setstripIdId] = useState("")
  const getstripIdId = async () => {
    try {
      const response = await fetch(`${base_url}partner-setting?payment_method=stripe`, {
        headers: {
          "app-key": app_key,
          "Authorization": token,
        },
      });

      let jsonData = await response.json();
      if (response.ok) {


        const encryptedText = jsonData?.data?.partnerSetting?.publisher_key;
        const encryptedText1 = jsonData?.data?.partnerSetting?.secret_key;
        const decoded = atob(encryptedText);
        const decoded1 = atob(encryptedText1);
        const key =  process.env.REACT_APP_SLL_KEY.padEnd(32, '0').slice(0, 32);
        const iv = process.env.REACT_APP_SLL_IV.padEnd(16, '0').slice(0, 16);



        if (!key || !iv) {
          console.error("Encryption key or IV is missing.");
          return;
        }

        const decryptedText = decryptData(decoded, key, iv);
        const decryptedText1 = decryptData(decoded1, key, iv);

        if (decryptedText) {
           // Update jsonData with decrypted values
           jsonData.data.partnerSetting.publisher_key = decryptedText;
           jsonData.data.partnerSetting.secret_key = decryptedText1; 
          setstripIdId(jsonData?.data);
        } else {
          console.error("Failed to decrypt the text.");
        }
      } else {
        console.log("Failed to fetch data", response.status);
      }
    } catch (error) {
      console.log("Fetch Error:", error.message);
    }
  };

  const decryptData = (encryptedData, key, iv) => {
    try {
      const parsedData = CryptoJS.enc.Base64.parse(encryptedData);


      const bytes = CryptoJS.AES.decrypt(
        { ciphertext: parsedData },
        CryptoJS.enc.Utf8.parse(key),
        {
          iv: CryptoJS.enc.Utf8.parse(iv),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      // Convert decrypted bytes to a string
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);


      // If decryptedData is empty, handle it
      if (!decryptedData) {
        throw new Error("Decrypted string is empty.");
      }

      return decryptedData;
    } catch (err) {
      console.error("Decryption Error:", err.message);
      console.error("Original Encrypted Data:", encryptedData);
      return null;
    }
  };
  const [fireBaseData, setfireBase] = useState("")
  const fireBaseCrdentioal = async () => {
    try {
      const response = await fetch(`${base_url}get-firebase-credentials`, {
        headers: {
          "app-key": app_key,
          "Authorization": token,
        }
      });
      const jsonData = await response.json();
      if (response.status === 200 || response.status === 201) {
        setfireBase(jsonData?.data)
      }

    } catch (error) {
      console.log(error?.message)
    }
  }





  return (
    <Authdata.Provider value={{
      fireBaseCrdentioal,
      fireBaseData,
      facebookCrdentioal,
      getGoogleClientId,
      googleClientId,
      fbAppId,
      getstripIdId,
      stripIdId,
      vendorDash,
      paymentData,
      vendorDashboard,
      clientDash,
      clientDashboard,
      home,
      homePage,
      homePgeLoading,
      setCities,
      getCities,
      cities,
      number,
      ContactNumber,
      terms,
      TermsandPrivacy,
      getSocialLInks,
      globalFAQs,
      FAQs,
      VendorEnquiry,
      enquiryVendor,
      sendRevision,
      markOrderComplete,
      profilePlan,
      getNotifcation,
      setPortFolioLink,
      setOrderLinkData,
      portfolioData,
      serviceTogaleStatus,
      getPortFolioImage,
      getAminty,
      amintyData,
      getOrderLinkData,
      updateNotification,
      orderLinkData,
      setOrderLinkDatas,
      slot,
      rescheduleOrder,
      delPortFolioImage,
      register,
      clientRegister,
      profileSetting,
      changeProfile,
      getState,
      getCity,
      stateData,
      cityData,
      error,
      sendCode,
      verifyCode,
      getSubCategry,
      getCategry,
      subCategory,
      category,
      venderLogin,
      setButtonLoding,
      socailLogin,
      buttonLoding,
      loginData,
      open,
      setOpen,
      passwordForget,
      filter,
      code,
      token,
      codeVerify,
      registerData,
      loading,
      Enquiry,
      step,
      setStep,
      setLogindata,
      changePassword,
      handleClick,
      setError,
      profileCode,
      sendCodeProfile,
      setVerify,
      setCode,
      resendEmail,
      vedorServiceData,
      vendorServices,
      getPlanByService,
      social_linkData,
      plansName,
      planNameData,
      planData,
      createPlans,
      editPlans,
      resendEmailData,
      forgetPasswordNew,
      forgetdata,
      clientRegisterData,
      sendEmailVerification,
      serviceSetting,
      adminserviceSetting,
      serviceSetingData,
      serviceSettingAdmin,
      base_url,
      adminserviceGellery,
      adminGellery,
      adminserviceGelleryDelete,
      availDaysData,
      availDays,
      getSlot,
      slotData,
      createSlot,
      updateStudio,
      addStudio,
      getStudio,
      studioData,
      img_url,
      getStudioGellery,
      studioGalleryData,
      delStudio,
      deleteStudioGellery,
      getvendorList,
      vendorList,
      getVendorProfile,
      profileData,
      updateslot,
      getDuration,
      duration,
      loadingPage,
      venderAvailiblty,
      slotDataVendor,
      addToCart,
      addCartResp,
      setCartResp,
      dashbord,
      getCart,
      cartData,
      setCartData,
      createOrder,
      orderDone,
      removeCart,
      orderData,
      getOrder,
      respForPass,
      orderStatus,
      orderStatusData,
      web_url,
      checkSlot,
      setOrderDone,
      setResendEmail,
      setforgetdata,
      setResponsestatus, responseStatus,
      studioProfileData,
      getStudioProfile,
      studioList,
      getstudioList,
      webSetting,
      webSettingData,
      setProfileData, togalPortFolioImage,
      getAdminService,
      getAdminServiceDetail,
      getAdminStudio,
      getAdminStudioDetail,
      adminservice,
      adminserviceDetail,
      adminStudio,
      adminStudioDetail,
      getStudioPortFolioImage,
      changeOrderStatus,
      setOrderData,
      contactUS,
      app_key,
      socialLinkStore,
      notificationData,
      userData,
      togalStudioImage,
      setAdminServiceDetail,
      getVendorPayment,
      requestPayment,
      getClientTex,
      clientTex,
      requestRefund,
      getavailableDate,
      availableDate,
      availbityLoad,
      setavailtyLoad,
      planLoad,
      setplanLoad,
      serviceLoding,
      setSeriveLoding,
      updateCart,
      vendorDashboardOrder,
      vendorDashOrder,

    }}>
      {children}
    </Authdata.Provider>
  )
}
export const useAuth = () => useContext(Authdata);