// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
const firebaseConfig = {
  apiKey: "AIzaSyARmOSge78L4g1HHdtLiiR9Loj_Yrgu598",
  authDomain: "photography-studio-hub-29700.firebaseapp.com",
  projectId: "photography-studio-hub-29700",
  storageBucket: "photography-studio-hub-29700.firebasestorage.app",
  messagingSenderId: "460459617026",
  appId: "1:460459617026:web:605a56df12e9e74d46301b",
};
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
// Register the service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}
export const auth = getAuth(app);
export { messaging, getToken, onMessage };
