import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Suspense, lazy, useEffect } from 'react';
import ScrollToTop from './Components/NavBarFooter/ScrollToTop';
import './ClientDashbord/SideBar/client.css';
import { useAuth } from './Context/ContextProvider';
import "./App.css"
import LodingWeb from './LodingWeb';
// Lazy-loaded components
const Home = lazy(() => import('./Pages/Home'));
const Aboutus = lazy(() => import('./Pages/Aboutus'));
const ContactUs = lazy(() => import('./Pages/ContactUs'));
const ExplorTelent = lazy(() => import('./Pages/ExplorTelent'));
const Login = lazy(() => import('./Pages/Login'));
const ClinetSignUp = lazy(() => import('./Pages/ClinetSignUp'));
const ClientForgetPassword = lazy(() => import('./Pages/ClientForgetPassword'));
const JoinOurTeam = lazy(() => import('./Pages/JoinOurTeam'));
const Cheackout = lazy(() => import('./Pages/Cheackout'));
const OrderSummry = lazy(() => import('./Pages/OrderSummry'));
const ListTalent = lazy(() => import('./Pages/ListTalent'));
const StudioSpace = lazy(() => import('./Pages/StudioSpace'));
const OurService = lazy(() => import('./Pages/OurService'));
const StudioPrfoile = lazy(() => import('./Pages/StudioPrfoile'));
const PhotograypyDetailPage = lazy(() => import('./Pages/PhotograypyDetailPage'));
const OurStudio = lazy(() => import('./Pages/OurStudio'));
const StdioDetail = lazy(() => import('./Pages/StdioDetail'));
const EmailVerificationPage = lazy(() => import('./Pages/EmailVerificationPage'));
const ForgetPassword = lazy(() => import('./Pages/ForgetPassword'));
const PurchaseOrder = lazy(() => import('./Pages/PurchaseOrder'));
const VenderProfie = lazy(() => import('./Pages/VenderProfie'));
const VenderPannel = lazy(() => import('./VenderDashbord/SideBar/VenderPannel'));
const ClientDashbord = lazy(() => import('./ClientDashbord/SideBar/ClientDashbord'));
const TandC = lazy(() => import('./Pages/TandC'));
const PrivacyPolicy = lazy(() => import('./Pages/PrivacyPolicy'));
const FAQs = lazy(() => import('./Pages/FAQs'));

function App() {
  const {
    web_url,
    facebookCrdentioal,
    getGoogleClientId,
    googleClientId,
    getstripIdId,
    stripIdId,
    webSetting,
    homePage,
    getAdminService,
    getAdminStudio,
    getSocialLInks,
    ContactNumber,
    getState,
    getAminty,
    getstudioList,
    fbAppId,
  } = useAuth();

  useEffect(() => {
    // Essential API calls
  
    getstudioList();
    getAdminService();
    getAdminStudio();
    webSetting();
    homePage();

    // Non-critical API calls deferred
    setTimeout(() => {
      getGoogleClientId();
      getSocialLInks();
      facebookCrdentioal();
      ContactNumber();
      getState();
      getAminty();
    }, 1000);
    setTimeout(() => {
      getState();
      getAminty();
      getstripIdId();
    }, 3000);

    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback={<LodingWeb/>}>
        <Routes>
          <Route path={`${web_url}`} element={<Home />} />
          <Route path={`${web_url}about`} element={<Aboutus />} />
          <Route path={`${web_url}contact`} element={<ContactUs />} />
          <Route path={`${web_url}explor-telent`} element={<ExplorTelent />} />
          <Route
            path={`${web_url}clinet-signin`}
            element={
              <Login
                app_id={fbAppId?.length ? fbAppId[0]?.value : ''}
                client_id={googleClientId?.length ? googleClientId[0]?.value : ''}
              />
            }
          />
          <Route
            path={`${web_url}clinet-sigup`}
            element={
              <ClinetSignUp
                app_id={fbAppId?.length ? fbAppId[0]?.value : ''}
                client_id={googleClientId?.length ? googleClientId[0]?.value : ''}
              />
            }
          />
          <Route
            path={`${web_url}checkout`}
            element={
              <Cheackout
                publicKey={stripIdId?.partnerSetting?.publisher_key}
                client_id={googleClientId?.length ? googleClientId[0]?.value : ''}
              />
            }
          />
          <Route path={`${web_url}forget-password`} element={<ClientForgetPassword />} />
          <Route path={`${web_url}join-our-team`} element={<JoinOurTeam />} />
          <Route path={`${web_url}order-summry`} element={<OrderSummry />} />
          <Route path={`${web_url}list-talent/:id`} element={<ListTalent />} />
          <Route path={`${web_url}studio-list/:id`} element={<StudioSpace />} />
          <Route path={`${web_url}our-service`} element={<OurService />} />
          <Route path={`${web_url}studio-profile/:id`} element={<StudioPrfoile />} />
          <Route path={`${web_url}service-detail/:id`} element={<PhotograypyDetailPage />} />
          <Route path={`${web_url}our-studio`} element={<OurStudio />} />
          <Route path={`${web_url}studio-detail/:id`} element={<StdioDetail />} />
          <Route path={`${web_url}email-verification`} element={<EmailVerificationPage />} />
          <Route path={`${web_url}reset_password`} element={<ForgetPassword />} />
          <Route path={`${web_url}order-placed`} element={<PurchaseOrder />} />
          <Route path={`${web_url}profile/:id/:id`} element={<VenderProfie />} />
          <Route path={`${web_url}vendor/*`} element={<VenderPannel />} />
          <Route path={`${web_url}client/*`} element={<ClientDashbord />} />
          <Route path={`${web_url}term-condition`} element={<TandC />} />
          <Route path={`${web_url}privacy-policy`} element={<PrivacyPolicy />} />
          <Route path={`${web_url}FAQs`} element={<FAQs />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
